/**
 * @generated SignedSource<<8376e71689550fb21037af5886aeaa14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockKind = "banner" | "channels" | "collection_folder" | "community_welcome_hero" | "content" | "continue_your_products" | "curriculum" | "experience_details" | "featured_items" | "feed" | "hero" | "leaderboard" | "members_list" | "memberships" | "pathway_sequence" | "product_admin" | "recently_viewed" | "rich_text" | "upcoming_events" | "welcome_banner" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardBlockItemFragment$data = {
  readonly id: string;
  readonly kind: DashboardBlockKind;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"BannerDashboardBlockFragment" | "ChannelsDashboardBlockFragment" | "CollectionFolderDashboardBlockFragment" | "CommunityWelcomeHeroDashboardBlockFragment" | "ContentDashboardBlockFragment" | "ContinueYourProductsDashboardBlockFragment" | "CurriculumDashboardBlockFragment" | "EventsDashboardBlockFragment" | "ExperienceDetailsDashboardBlockFragment" | "FeaturedItemsDashboardBlockFragment" | "FeedDashboardBlockFragment" | "HeroDashboardBlockFragment" | "LeaderboardDashboardBlockFragment" | "MembersDashboardBlockFragment" | "PathwaySequenceDashboardBlockFragment" | "ProductAdminDashboardBlockFragment" | "RecentlyViewedDashboardBlockFragment" | "RichTextDashboardBlockFragment" | "WelcomeBannerDashboardBlockFragment">;
  readonly " $fragmentType": "DashboardBlockItemFragment";
};
export type DashboardBlockItemFragment$key = {
  readonly " $data"?: DashboardBlockItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardBlockItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeaderboardDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WelcomeBannerDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeaturedItemsDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembersDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RichTextDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceDetailsDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventsDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurriculumDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChannelsDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BannerDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionFolderDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommunityWelcomeHeroDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductAdminDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PathwaySequenceDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContinueYourProductsDashboardBlockFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RecentlyViewedDashboardBlockFragment"
    }
  ],
  "type": "DashboardBlock",
  "abstractKey": "__isDashboardBlock"
};

(node as any).hash = "70149e13adab7035eba3e6c2f9f49925";

export default node;
