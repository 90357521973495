import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import ProductAdminDashboardActions, {
  ProductAdminDashboardActionsSkeleton,
} from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/ProductAdminDashboardActions"
import ProductAdminDashboardStatistics, {
  ProductAdminDashboardStatisticsSkeleton,
} from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/ProductAdminDashboardStatistics"
import ProductAdminInfoToolbar, {
  ProductAdminInfoToolbarSkeleton,
} from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/ProductAdminInfoToolbar"
import { ProductAdminDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/ProductAdminDashboardBlockFragment.graphql"

import Relay from "@/relay/relayUtils"
import MisconfiguredStripeObjectsList from "@/stripe/components/MisconfiguredStripeObjectsList"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: ProductAdminDashboardBlockFragment$key
  index?: number
}

function ProductAdminDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const theme = useTheme()

  const block = useFragment<ProductAdminDashboardBlockFragment$key>(
    graphql`
      fragment ProductAdminDashboardBlockFragment on ProductAdminDashboardBlock {
        id
        productId
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
        ...ProductAdminInfoToolbarFragment
      }
    `,
    dashboardBlockKey
  )

  if (!block) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classes.container}>
        <div className={classes.header}>
          <DiscoText
            variant={"body-lg-600"}
            lineHeight={28}
            color={
              theme.palette.type === "dark" ? "groovy.onDark.100" : "groovy.neutral.600"
            }
          >
            {"Admin Overview"}
          </DiscoText>
          <DiscoChip
            leftIcon={<DiscoIcon icon={"eye"} width={16} height={16} />}
            label={"Admin only"}
            customBackgroundColor={theme.palette.groovy.onDark[100]}
            customTextColor={theme.palette.groovy.neutral[400]}
          />
        </div>

        <ProductAdminInfoToolbar dashboardBlockKey={block} />

        {activeOrganization?.stripeAccountId && block.productId && (
          <MisconfiguredStripeObjectsList productId={block.productId} />
        )}

        <ProductAdminDashboardStatistics blockId={block.id} />
        <ProductAdminDashboardActions blockId={block.id} />
      </div>
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0, 2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

export function ProductAdminDashboardBlockSkeleton() {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <DiscoText
          variant={"body-lg-600"}
          lineHeight={28}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.100" : "groovy.neutral.600"
          }
        >
          {"Admin Overview"}
        </DiscoText>
        <DiscoChip
          leftIcon={<DiscoIcon icon={"eye"} width={16} height={16} />}
          label={"Admin only"}
          customBackgroundColor={theme.palette.groovy.onDark[100]}
          customTextColor={theme.palette.groovy.neutral[400]}
        />
      </div>
      <ProductAdminInfoToolbarSkeleton />
      <ProductAdminDashboardStatisticsSkeleton />
      <ProductAdminDashboardActionsSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ProductAdminDashboardBlock,
  skeleton: ProductAdminDashboardBlockSkeleton,
})
