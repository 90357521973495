import CreateChatChannelButton from "@/apps/list/app/chat/CreateChatChannelButton"
import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import {
  sortStreamChannelsByUnreads,
  useStreamChannels,
} from "@/core/context/StreamChatContext"
import AddWithCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import ChannelsDashboardBlockItem, {
  ChannelsDashboardBlockItemSkeleton,
} from "@/dashboard/blocks/kinds/ChannelsDashboardBlockItem"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { ChannelsDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockFragment.graphql"
import { ChannelsDashboardBlockPaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockPaginationFragment.graphql"
import { ChannelsDashboardBlockPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockPaginationQuery.graphql"
import { ChannelsDashboardBlockQuery } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import DiscoCarousel, { DiscoCarouselSkeleton } from "@disco-ui/carousel/DiscoCarousel"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"

interface Props {
  dashboardBlockKey: ChannelsDashboardBlockFragment$key
  index?: number
}

function ChannelsDashboardBlock({ dashboardBlockKey, index }: Props) {
  const classes = useStyles()
  const { connectedApps } = useConnectedProductApps()
  const activeProduct = useActiveProduct()

  const block = useFragment<ChannelsDashboardBlockFragment$key>(
    graphql`
      fragment ChannelsDashboardBlockFragment on ChannelsDashboardBlock {
        productId
        title
        position
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const { product } = useLazyLoadQuery<ChannelsDashboardBlockQuery>(
    graphql`
      query ChannelsDashboardBlockQuery($id: ID!, $first: Int) {
        product: node(id: $id) {
          ... on Product {
            ...ChannelsDashboardBlockPaginationFragment @arguments(first: $first)
          }
        }
      }
    `,
    {
      id: block.productId || "",
      first: 3,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext } = usePaginationFragment<
    ChannelsDashboardBlockPaginationQuery,
    ChannelsDashboardBlockPaginationFragment$key
  >(
    graphql`
      fragment ChannelsDashboardBlockPaginationFragment on Product
      @refetchable(queryName: "ChannelsDashboardBlockPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        chatChannels(isDashboard: true, first: $first, after: $after)
          @connection(key: "ChannelsDashboardBlock__chatChannels") {
          __id
          totalCount
          edges {
            node {
              id
              externalChannelId
              productApp {
                id
              }
              ...ChannelsDashboardBlockItemFragment
            }
          }
        }
      }
    `,
    product
  )

  const channels = Relay.connectionToArray(data?.chatChannels)

  const streamChannels = useStreamChannels(channels.map((c) => c.externalChannelId))
  const sortedStreamChannels = sortStreamChannelsByUnreads(streamChannels)
  const sortedChannels =
    sortedStreamChannels
      ?.map((ssc) => channels.find((c) => c.externalChannelId === ssc.id)!)
      .filter(Boolean) || []

  const permissions = useActiveProductOrOrganizationPermissions()
  const canCreateChannel = permissions.has("chat.manage")

  // If no channels app connected, return null
  if (activeProduct && !connectedApps.has("chat_channel")) return null

  if (!data) return null

  if (!canCreateChannel && !sortedChannels.length) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoCarousel
        title={block.title}
        moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        data={sortedChannels}
        slidesPerView={"auto"}
        classes={{
          slide: classes.item,
        }}
        item={(channel) => (
          <ChannelsDashboardBlockItem key={channel.id} channelKey={channel} />
        )}
        totalCount={data?.chatChannels?.totalCount}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(3),
          isLoading: isLoadingNext,
        }}
        emptyState={
          canCreateChannel && (
            <CreateChatChannelButton>
              {(buttonProps) => {
                return (
                  <DiscoEmptyState
                    className={classes.emptyState}
                    testid={"ChannelsDashboardBlock"}
                    icon={<AddWithCircleIcon width={40} height={40} />}
                    title={"No Channels"}
                    subtitle={"Create your first channel now!"}
                    onClick={buttonProps.onClick as VoidFunction}
                    variant={"compact"}
                  />
                )
              }}
            </CreateChatChannelButton>
          )
        }
      />
    </DashboardBlockItemTemplate>
  )
}

export function ChannelsDashboardBlockSkeleton() {
  return (
    <DiscoCarouselSkeleton
      item={<ChannelsDashboardBlockItemSkeleton />}
      slidesPerView={3}
      showTitle
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
  item: {
    width: "360px",
  },
}))

export default Relay.withSkeleton({
  component: ChannelsDashboardBlock,
  skeleton: ChannelsDashboardBlockSkeleton,
})
