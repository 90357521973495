import { LeaderboardDashboardBlockCarouselItemFragment$key } from "@/dashboard/blocks/kinds/__generated__/LeaderboardDashboardBlockCarouselItemFragment.graphql"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  score: number
  userKey: LeaderboardDashboardBlockCarouselItemFragment$key
  badgeIcon?: React.ReactNode
}

function LeaderboardDashboardBlockCarouselItem({
  score,
  userKey,
  badgeIcon,
  testid = "LeaderboardDashboardBlockCarouselItem",
}: Props) {
  const classes = useStyles()

  const user = useFragment<LeaderboardDashboardBlockCarouselItemFragment$key>(
    graphql`
      fragment LeaderboardDashboardBlockCarouselItemFragment on User {
        id
        fullName
        ...ProfileAvatarFragment
      }
    `,
    userKey
  )

  return (
    <DiscoContainerButton className={classes.card} testid={testid}>
      <OpenProfilePopoverButton userId={user.id} stopPropagation>
        {(buttonProps) => {
          return (
            <div {...buttonProps} className={classes.container}>
              <div className={classes.avatarContainer}>
                <ProfileAvatar
                  testid={`${testid}.ProfileAvatar`}
                  userKey={user}
                  badgeClassName={classes.badge}
                  size={96}
                  badgeIcon={badgeIcon}
                />
              </div>
              <DiscoText variant={"body-lg-600"} truncateText={1}>
                {user.fullName}
              </DiscoText>
              <DiscoText variant={"body-sm"} color={"groovy.neutral.500"}>
                {`${score} ${pluralize("pt", score)}`}
              </DiscoText>
            </div>
          )
        }}
      </OpenProfilePopoverButton>
    </DiscoContainerButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.xs,
    border: theme.palette.constants.borderDashboardCard,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  avatarContainer: {
    width: "90px",
    marginBottom: theme.spacing(1.5),
  },
  badge: {
    right: "3px",
    bottom: "3px",
  },
}))

export default LeaderboardDashboardBlockCarouselItem
