import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { AddDashboardBlockFormState } from "@/dashboard/add/AddDashboardBlockModal"
import {
  DashboardBlockPosition,
  EditDashboardBlockFormFragment$data,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  EditDashboardBlockFormMutation,
  EditDashboardBlockInput,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import HeroDashboardBlockForm from "@/dashboard/form/HeroDashboardBlockForm"
import BannerDashboardBlockForm from "@/dashboard/form/BannerDashboardBlockForm"
import ChannelsDashboardBlockForm from "@/dashboard/form/ChannelsDashboardBlockForm"
import CollectionFolderDashboardBlockForm from "@/dashboard/form/CollectionFolderDashboardBlockForm"
import CommunityWelcomeHeroDashboardBlockForm from "@/dashboard/form/CommunityWelcomeHeroDashboardBlockForm"
import ContentDashboardBlockForm from "@/dashboard/form/ContentDashboardBlockForm"
import ContinueYourProductsDashboardBlockForm from "@/dashboard/form/ContinueYourProductsDashboardBlockForm"
import CurriculumDashboardBlockForm from "@/dashboard/form/CurriculumDashboardBlockForm"
import EventsDashboardBlockForm from "@/dashboard/form/EventsDashboardBlockForm"
import ExperienceDetailsDashboardBlockForm from "@/dashboard/form/ExperienceDetailsDashboardBlockForm"
import FeaturedItemsDashboardBlockForm from "@/dashboard/form/FeaturedItemsDashboardBlockForm"
import FeedDashboardBlockForm from "@/dashboard/form/FeedDashboardBlockForm"
import LeaderboardDashboardBlockForm from "@/dashboard/form/LeaderboardDashboardBlockForm"
import MembersListDashboardBlockForm from "@/dashboard/form/MembersListDashboardBlockForm"
import RichTextDashboardBlockForm from "@/dashboard/form/RichTextDashboardBlockForm"
import WelcomeBannerDashboardBlockForm from "@/dashboard/form/WelcomeBannerDashboardBlockForm"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { DashboardBlockKind } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"

export type DashboardBlockFormStore =
  | FormStore<AddDashboardBlockFormState>
  | FormStore<
      EditDashboardBlockInput & Partial<EditDashboardBlockFormFragment$data>,
      EditDashboardBlockFormMutation
    >

export interface BlockFormConfig {
  form: React.FC<{ form: DashboardBlockFormStore; onClose: () => void }>
  name: string
  description: string
  alert?: string
}

export function useDashboardBlockKindForms(
  position?: DashboardBlockPosition
): Partial<Record<Exclude<DashboardBlockKind, "%future added value">, BlockFormConfig>> {
  const labels = useLabels()
  const activeProduct = useActiveProduct()
  const dashboard = useDashboardContext()!
  const singleColumnExperienceDashboard =
    activeProduct?.dashboard?.layout === "one_column"

  return {
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome &&
      !dashboard.isOneColumn && {
        welcome_banner: {
          form: WelcomeBannerDashboardBlockForm,
          name: "Community Hero",
          description: "Share a community welcome message with a hero image.",
        },
      }),
    ...(!activeProduct &&
      dashboard.isOneColumn && {
        hero: {
          form: HeroDashboardBlockForm,
          name: "Hero Block",
          description:
            "A hero banner with a custom background (image or solid color) and a welcome message option. Only one Hero block may be added.",
          alert: "The Hero block will be added to the top of the page.",
        },
      }),
    ...(dashboard.isCommunityWelcome && {
      community_welcome_hero: {
        form: CommunityWelcomeHeroDashboardBlockForm,
        name: "Hero",
        description: "Set a cover image and text to welcome to your community.",
      },
    }),
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        continue_your_products: {
          form: ContinueYourProductsDashboardBlockForm,
          name: "Jump Back In Block",
          description: `Displays ${labels.admin_experience.plural} that ${labels.organization_member.plural} were recently in, letting them jump back in where they left off. Only one Jump Back In block may be added.`,
        },
      }),
    ...(!activeProduct &&
      !dashboard.isCommunityWelcome && {
        recently_viewed: {
          form: () => null,
          name: "Recently Viewed Block",
          description: `Displays the content or events ${labels.organization_member.plural} have viewed in the last 7 days. Only one Recently Viewed block may be added.`,
          alert: "Only one Recently Viewed block may be added",
        },
      }),
    ...(singleColumnExperienceDashboard && {
      banner: {
        form: BannerDashboardBlockForm,
        name: "Product Banner",
        description: `Set an image as ${labels.admin_experience.singular} banner and provide high-level ${labels.admin_experience.singular} details.`,
      },
    }),
    ...(!dashboard.isCommunityWelcome && {
      members_list: {
        form: MembersListDashboardBlockForm,
        name: `${labels.admin_member.plural} Block`,
        description: "Displays the member directory to encourage social interactions.",
      },
    }),
    ...(position === "main" &&
      !dashboard.isCommunityWelcome && {
        feed: {
          form: FeedDashboardBlockForm,
          name: "Posts Block",
          description: `Aggregates posts from across all Feeds the ${labels.organization_member.singular} has access to, displayed from newest to oldest. Only one Posts block may be added.`,
          alert: `Only one Feed block can be added. This block will aggregate all feeds across this ${
            activeProduct ? labels.experience.singular : "community"
          }.`,
        },
      }),
    ...(!dashboard.isCommunityWelcome && {
      upcoming_events: {
        form: EventsDashboardBlockForm,
        name: "Upcoming Events Block",
        description:
          "Displays the latest and upcoming events to boost attendance. Only one Events block may be added.",
      },
    }),
    ...(!dashboard.isCommunityWelcome && {
      leaderboard: {
        form: LeaderboardDashboardBlockForm,
        name: "Leaderboard Block",
        description: `Show a leaderboard based on ${labels.admin_member.plural} engagement score in your selected time period.`,
      },
    }),
    rich_text: {
      form: RichTextDashboardBlockForm,
      name: "Rich Text Block",
      description:
        "Use the rich text editor to create a custom block that allows you to add different types of content (text, images, videos, attachments, and embeds).",
    },
    featured_items: {
      form: FeaturedItemsDashboardBlockForm,
      name: `Featured ${labels.admin_experience.plural}`,
      description: `Display published ${labels.admin_experience.plural} to drive engagement and registration.`,
    },
    memberships: {
      form: FeaturedItemsDashboardBlockForm,
      name: "Featured Memberships",
      description:
        "Spotlight Memberships from your community to drive engagement and registration.",
    },
    ...(!dashboard.isCommunityWelcome && {
      content: {
        form: ContentDashboardBlockForm,
        name: "Content Block",
        description:
          "Displays a selected content from across your community (lesson, quiz, assignment, survey, etc.)",
      },
    }),

    collection_folder: {
      form: CollectionFolderDashboardBlockForm,
      name: "Collection Folder Block",
      description:
        "Displays a selected collection from within Products or collections apps.",
    },
    // Only allow for two column dashboards
    ...(activeProduct &&
      !singleColumnExperienceDashboard && {
        experience_details: {
          form: ExperienceDetailsDashboardBlockForm,
          name: `${labels.admin_experience.singular} Details`,
          description: `Provide general details about the ${labels.admin_experience.singular}`,
        },
      }),
    ...(activeProduct && {
      curriculum: {
        form: CurriculumDashboardBlockForm,
        name: labels.curriculum.singular,
        description: "Show incomplete and upcoming Modules to drive engagement.",
      },
    }),
    ...(activeProduct &&
      position === "main" && {
        channels: {
          form: ChannelsDashboardBlockForm,
          name: "Channels",
          description: `Show discussions across the ${labels.admin_experience.singular} channels to drive engagement.`,
        },
      }),
  }
}
