/**
 * @generated SignedSource<<9cbbfe3e9108d780cb8fa7e6ab1cefc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DashboardMigrationModalMutation$variables = Record<PropertyKey, never>;
export type DashboardMigrationModalMutation$data = {
  readonly migrateDashboard: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly organization: {
        readonly dashboard: {
          readonly published: boolean;
        } | null;
        readonly forYouDashboard: {
          readonly published: boolean;
        } | null;
      };
    } | null;
  };
};
export type DashboardMigrationModalMutation = {
  response: DashboardMigrationModalMutation$data;
  variables: DashboardMigrationModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardMigrationModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MigrateDashboardResponse",
        "kind": "LinkedField",
        "name": "migrateDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "forYouDashboard",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "dashboard",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardMigrationModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MigrateDashboardResponse",
        "kind": "LinkedField",
        "name": "migrateDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "forYouDashboard",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dashboard",
                    "kind": "LinkedField",
                    "name": "dashboard",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfb11fbff2b6d20e419d868c5144b36d",
    "id": null,
    "metadata": {},
    "name": "DashboardMigrationModalMutation",
    "operationKind": "mutation",
    "text": "mutation DashboardMigrationModalMutation {\n  migrateDashboard {\n    node {\n      organization {\n        forYouDashboard {\n          published\n          id\n        }\n        dashboard {\n          published\n          id\n        }\n        id\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61c27e2b7321d6eb92fd468bd42c44ff";

export default node;
