import Badge from "@/admin/experiences/badges/Badge"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { StreamChatUserData, useStreamChannel } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ChannelsDashboardBlockItemFragment$key } from "@/dashboard/blocks/kinds/__generated__/ChannelsDashboardBlockItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useStreamChannelsTotalUnreadCount from "@components/chat/channel/util/useStreamChannelsTotalUnreadCount"
import CountBadge from "@components/square-count-badge/CountBadge"
import { DiscoIcon, DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { DiscoCarouselItem } from "@disco-ui/carousel/DiscoCarouselItem"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { FormatMessageResponse } from "stream-chat"
import { DefaultStreamChatGenerics, useChatContext } from "stream-chat-react"

interface ChannelsDashboardBlockItemProps extends TestIDProps {
  channelKey: ChannelsDashboardBlockItemFragment$key
}

function ChannelsDashboardBlockItem({
  channelKey,
  testid = "ChannelsDashboardBlockItem",
}: ChannelsDashboardBlockItemProps) {
  const timeZone = useUserTimezone()
  const classes = useStyles()
  const activeProduct = useActiveProduct()!
  const channel = useFragment<ChannelsDashboardBlockItemFragment$key>(
    graphql`
      fragment ChannelsDashboardBlockItemFragment on ChatChannel {
        id
        externalChannelId
        visibility
        productApp {
          customAppTitle
          visibility
          ordering
          badge {
            ...BadgeFragment
          }
        }
      }
    `,
    channelKey
  )

  const theme = useTheme()
  const { client: stream } = useChatContext()
  const streamChannel = useStreamChannel(channel.externalChannelId)
  const numUnreads = useStreamChannelsTotalUnreadCount(
    streamChannel ? [streamChannel] : []
  )
  const lastMessage: FormatMessageResponse<DefaultStreamChatGenerics> | undefined =
    streamChannel?.lastMessage()

  if (!channel) return null
  if (!channel.productApp) return null
  const { badge, customAppTitle } = channel.productApp

  const channelPath = generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
    channelId: channel.id,
    productSlug: activeProduct.slug,
  })

  const { reply_count, reaction_counts } = lastMessage || {}

  const reactionCount = reaction_counts
    ? Object.values(reaction_counts).reduce((val, count) => val + count, 0)
    : 0

  return (
    <DiscoCarouselItem
      to={channelPath}
      testid={`${testid}.${customAppTitle}`}
      className={classes.channel}
    >
      <div className={classes.badgeAndTitle}>
        <div className={classes.icon}>{<Badge badgeKey={badge} />}</div>
        <DiscoText variant={"body-md-600"} truncateText={1}>
          {customAppTitle}
        </DiscoText>
      </div>

      {renderMessagePreview()}

      <div className={classes.unreads}>
        {Boolean(numUnreads) && <CountBadge count={numUnreads} />}
      </div>
      <div className={classes.footer}>
        <DiscoText variant={"body-sm"} className={classes.footerText}>
          <DiscoIcon
            icon={"iconsax.custom-face-content"}
            width={16}
            height={16}
            color={
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[200]
                : theme.palette.groovy.neutral[700]
            }
          />
          {reactionCount}
        </DiscoText>

        <DiscoText variant={"body-sm"} className={classes.footerText}>
          <DiscoIcon
            icon={"iconsax.message"}
            width={16}
            height={16}
            color={
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[200]
                : theme.palette.groovy.neutral[700]
            }
          />
          {reply_count}
        </DiscoText>
      </div>
    </DiscoCarouselItem>
  )

  function renderMessagePreview() {
    if (!streamChannel) {
      return <DiscoTextSkeleton width={"75%"} />
    }

    if (!lastMessage) return null
    const user = lastMessage.user ? (lastMessage.user as StreamChatUserData) : null
    if (!user) return null

    const name =
      lastMessage.user_id === stream?.user?.id
        ? "You"
        : `${lastMessage.user?.first_name} ${lastMessage.user?.last_name}`
    const sentAt = lastMessage.created_at

    const content =
      lastMessage.text || (lastMessage.attachments?.length ? "Sent attachment" : "")

    return (
      <>
        <div className={classes.avatarDetails}>
          <UserAvatar
            user={{
              id: Relay.toGlobalId("User", user.disco_user_id),
              first_name: user.first_name,
              last_name: user.last_name,
              avatar: user.avatar,
              name: user.name,
              is_test_user: user.is_disco_test_user || false,
            }}
            size={40}
          />
          <div className={classes.nameAndDate}>
            <DiscoText>{`${name}`}</DiscoText>
            <DiscoText variant={"body-xs"} color={"text.secondary"}>
              {`${formatDateWithOptions({
                timeZone,
                format: DATE_FORMAT.DEFAULT,
              })(sentAt)} at
                  ${formatDateWithOptions({
                    timeZone,
                    format: DATE_FORMAT.SHORT_TIME_FORMAT,
                  })(sentAt)}`}
            </DiscoText>
          </div>
        </div>
        <DiscoText
          variant={"body-sm"}
          color={theme.palette.type === "dark" ? "common.white" : "groovy.neutral.500"}
        >
          {content}
        </DiscoText>
      </>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  channel: {
    minHeight: "244px",
  },
  badgeAndTitle: {
    display: "flex",
    marginBottom: theme.spacing(1),
    gap: theme.spacing(0.5),
    width: "100%",
  },
  unreads: {
    display: "flex",
    gap: theme.spacing(1),
  },
  caption: {
    fontWeight: 400,
  },
  channelLink: {
    width: "100%",
    alignItems: "center",
    minWidth: 0,
  },
  avatarDetails: {
    display: "flex",
    alignItems: "center",
  },
  nameAndDate: {
    marginLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  icon: {
    width: "20px",
    alignSelf: "center",
    display: "flex",
  },
  footerText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  footer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}))

export function ChannelsDashboardBlockItemSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.channelLink}>
      <DiscoSection className={classes.channel}>
        <div className={classes.badgeAndTitle}>
          <Skeleton width={"24px"} />
          <DiscoTextSkeleton variant={"body-md-600"} width={"80%"} />
        </div>

        <div className={classes.unreads}>
          <DiscoTextSkeleton
            variant={"body-xs-500"}
            className={classes.caption}
            width={"60%"}
          />
        </div>
      </DiscoSection>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ChannelsDashboardBlockItem,
  skeleton: ChannelsDashboardBlockItemSkeleton,
})
