/**
 * @generated SignedSource<<4dfe79ca192f8ba36af99fc30a7e5b7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockKind = "banner" | "channels" | "collection_folder" | "community_welcome_hero" | "content" | "continue_your_products" | "curriculum" | "experience_details" | "featured_items" | "feed" | "hero" | "leaderboard" | "members_list" | "memberships" | "pathway_sequence" | "product_admin" | "recently_viewed" | "rich_text" | "upcoming_events" | "welcome_banner" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardBlockAdminDropdownFragment$data = {
  readonly id: string;
  readonly kind: DashboardBlockKind;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"EditDashboardBlockButtonFragment">;
  readonly " $fragmentType": "DashboardBlockAdminDropdownFragment";
};
export type DashboardBlockAdminDropdownFragment$key = {
  readonly " $data"?: DashboardBlockAdminDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardBlockAdminDropdownFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditDashboardBlockButtonFragment"
    }
  ],
  "type": "DashboardBlock",
  "abstractKey": "__isDashboardBlock"
};

(node as any).hash = "6a8ed88ddf758429b0623bd049620128";

export default node;
