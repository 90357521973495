import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { FeaturedItemsDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/FeaturedItemsDashboardBlockFragment.graphql"
import { FeaturedItemsDashboardBlockPaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/FeaturedItemsDashboardBlockPaginationFragment.graphql"
import { FeaturedItemsDashboardBlockPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/FeaturedItemsDashboardBlockPaginationQuery.graphql"
import { FeaturedItemsDashboardBlockQuery } from "@/dashboard/blocks/kinds/__generated__/FeaturedItemsDashboardBlockQuery.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import MembershipPlanDetailsCard from "@/membership-plan/register/MembershipPlanDetailsCard"
import { MembershipPlanDetailsCardFragment$data } from "@/membership-plan/register/__generated__/MembershipPlanDetailsCardFragment.graphql"
import ProductCard from "@/product/card/ProductCard"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: FeaturedItemsDashboardBlockFragment$key
  index?: number
}

function FeaturedItemsDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const history = useHistory()
  const classes = useStyles()
  const experienceLabel = useLabel("experience")

  const block = useFragment<FeaturedItemsDashboardBlockFragment$key>(
    graphql`
      fragment FeaturedItemsDashboardBlockFragment on FeaturedItemsDashboardBlock {
        id
        position
        dashboardId
        showCountdown
        featuredKind
        dashboard {
          layout
        }
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  // Separated from the fragment to speed up dashboard loading
  const { featuredItemsBlock } = useLazyLoadQuery<FeaturedItemsDashboardBlockQuery>(
    graphql`
      query FeaturedItemsDashboardBlockQuery($id: ID!, $first: Int) {
        featuredItemsBlock: node(id: $id) {
          ... on FeaturedItemsDashboardBlock {
            ...FeaturedItemsDashboardBlockPaginationFragment @arguments(first: $first)
          }
        }
      }
    `,
    {
      id: block.id,
      first: 3,
    }
  )

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext, refetch } =
    usePaginationFragment<
      FeaturedItemsDashboardBlockPaginationQuery,
      FeaturedItemsDashboardBlockPaginationFragment$key
    >(
      graphql`
        fragment FeaturedItemsDashboardBlockPaginationFragment on FeaturedItemsDashboardBlock
        @refetchable(queryName: "FeaturedItemsDashboardBlockPaginationQuery")
        @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
          id
          featuredProducts(first: $first, after: $after)
            @connection(key: "FeaturedItemsDashboardBlock__featuredProducts") {
            __id
            totalCount
            edges {
              node {
                id
                slug
                ...ProductCardFragment
                ...MembershipPlanDetailsCardFragment
                  @arguments(hideDrafts: true, hideNonPublic: true)
              }
            }
          }
        }
      `,
      featuredItemsBlock
    )

  const carouselProps = useDashboardBlockCarouselSize(3, block, "center")

  useSubscribeToInvalidationState(data ? [data.featuredProducts.__id] : [], () => {
    refetch({})
  })

  if (!data) return null

  const featuredProducts = Relay.connectionToArray(data?.featuredProducts)
  const isHighlightingMemberships = block.featuredKind === "membership"

  if (!featuredProducts.length) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoCarousel
        classes={{
          slide: classes.slide,
        }}
        {...carouselProps}
        title={`Featured ${getFeaturedName()}`}
        moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(3),
          isLoading: isLoadingNext,
        }}
        totalCount={data?.featuredProducts?.totalCount}
        data={featuredProducts}
        item={(product) => {
          if (isHighlightingMemberships) {
            return (
              <MembershipPlanDetailsCard
                membershipPlanKey={product}
                onClick={handleSelectPlan}
                hideDescription
                className={classes.card}
                isDashboardBlock
              />
            )
          }
          return (
            <ProductCard
              key={product.id}
              productKey={product}
              hideButton={isHighlightingMemberships}
              hideAdminActions
              className={classes.card}
              isDashboardBlock
              titleVariant={"heading-xs-600"}
              addShadow
            />
          )
        }}
      />
    </DashboardBlockItemTemplate>
  )

  function getFeaturedName() {
    switch (block.featuredKind) {
      case "experience":
        return experienceLabel.plural
      case "event":
        return "Events"
      case "collection":
        return "Collections"
      case "membership":
        return "Memberships"
      default:
        return ""
    }
  }

  function handleSelectPlan(membershipPlan: MembershipPlanDetailsCardFragment$data) {
    history.push(
      `${ROUTE_NAMES.COMMUNITY.MEMBERSHIPS.ROOT}?mpid=${membershipPlan.id}&membershipPlanSlug=${membershipPlan.slug}`
    )
  }
}

const useStyles = makeUseStyles({
  slide: {
    width: "254px",
    height: "260px",
  },
  card: {
    textAlign: "left",
  },
})

function FeaturedItemsDashboardBlockSkeleton() {
  const theme = useTheme()
  return (
    <Skeleton
      variant={"rect"}
      height={"386px"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
      }}
    />
  )
}

export default Relay.withSkeleton({
  component: FeaturedItemsDashboardBlock,
  skeleton: FeaturedItemsDashboardBlockSkeleton,
})
