import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { WelcomeBannerDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/WelcomeBannerDashboardBlockFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: WelcomeBannerDashboardBlockFragment$key
  index?: number
}

function WelcomeBannerDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props
  const onShowHoverClasses = useShowOnHoverStyles()

  const block = useFragment<WelcomeBannerDashboardBlockFragment$key>(
    graphql`
      fragment WelcomeBannerDashboardBlockFragment on WelcomeBannerDashboardBlock {
        title
        subtitle
        richEditorDescription
        cover
        organization {
          cover
        }
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const classes = useStyles()
  const theme = useTheme()

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div
        className={classNames(classes.container, onShowHoverClasses.hoverable)}
        data-testid={"WelcomeBannerDashboardBlock.container"}
      >
        <CoverPhoto
          testid={"WelcomeBannerDashboardBlock.coverPhoto"}
          coverPhoto={block.cover || block.organization.cover}
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
        />
        <div className={classes.headerContainer}>
          <div className={classes.headerTitleContainer}>
            <div className={classes.headerTitleTextContainer}>
              <DiscoText
                variant={"body-xs-600-uppercase"}
                color={"primary.main"}
                marginBottom={0.5}
                testid={"WelcomeBannerDashboardBlock.subtitle"}
              >
                {block.subtitle}
              </DiscoText>
              <DiscoText
                variant={"heading-md"}
                testid={"WelcomeBannerDashboardBlock.title"}
              >
                {block.title}
              </DiscoText>
            </div>

            <DashboardBlockAdminDropdown
              dashboardBlockKey={block}
              className={classNames(classes.overflow, onShowHoverClasses.showable)}
            />
          </div>

          <DiscoEditor
            key={block.richEditorDescription}
            testid={"WelcomeBannerDashboardBlock.description"}
            textColor={theme.palette.text.secondary}
            defaultValue={block.richEditorDescription}
            readOnly
            disableGutter
          />
        </div>
      </div>
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.xs,
    border: theme.palette.constants.borderDashboardCard,

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  overflow: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(3.5),
    backgroundColor: theme.palette.background.paper,
  },
  headerContainer: {
    padding: theme.spacing(3),
  },
  headerTitleContainer: {
    marginBottom: theme.spacing(1.5),
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitleTextContainer: {
    flexGrow: 1,
  },
}))

export default WelcomeBannerDashboardBlock
