/**
 * @generated SignedSource<<763fe1a73e7b049fd587ddaaeb0fb365>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProductAdminDashboardActionCreateMutation$variables = {
  id: string;
};
export type ProductAdminDashboardActionCreateMutation$data = {
  readonly response: {
    readonly node: {
      readonly viewerHasDismissed: boolean;
    } | null;
  };
};
export type ProductAdminDashboardActionCreateMutation = {
  response: ProductAdminDashboardActionCreateMutation$data;
  variables: ProductAdminDashboardActionCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasDismissed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductAdminDashboardActionCreateMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateAdminActionDismissalResponse",
        "kind": "LinkedField",
        "name": "createAdminActionDismissal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAdminAction",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductAdminDashboardActionCreateMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CreateAdminActionDismissalResponse",
        "kind": "LinkedField",
        "name": "createAdminActionDismissal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductAdminAction",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea0698e0db1be693a65208f88e13ff16",
    "id": null,
    "metadata": {},
    "name": "ProductAdminDashboardActionCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductAdminDashboardActionCreateMutation(\n  $id: ID!\n) {\n  response: createAdminActionDismissal(id: $id) {\n    node {\n      viewerHasDismissed\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "abfaa63d86ce28dada34a98386618208";

export default node;
