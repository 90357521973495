import ContentUsageGridItem, {
  ContentUsageGridItemSkeleton,
} from "@/content-usage/modules/grid/ContentUsageGridItem"
import ContentDashboardBlockAdminDropdown from "@/dashboard/blocks/kinds/ContentDashboardBlockAdminDropdown"
import { ContentDashboardBlockPreviewContentFragment$key } from "@/dashboard/blocks/kinds/__generated__/ContentDashboardBlockPreviewContentFragment.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentDashboardBlockPreviewContentProps {
  dashboardBlockKey: ContentDashboardBlockPreviewContentFragment$key
}

function ContentDashboardBlockPreviewContent({
  dashboardBlockKey,
}: ContentDashboardBlockPreviewContentProps) {
  const classes = useStyles()

  const block = useFragment<ContentDashboardBlockPreviewContentFragment$key>(
    graphql`
      fragment ContentDashboardBlockPreviewContentFragment on ContentDashboardBlock {
        position
        contentUsages {
          edges {
            node {
              id
              ...ContentUsageGridItemFragment
            }
          }
        }
        ...ContentDashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const contentUsages = Relay.connectionToArray(block.contentUsages)

  const carouselProps = useDashboardBlockCarouselSize(3, block, "center")

  return (
    <DiscoCarousel
      title={"Content"}
      classes={{
        slide: classes.slide,
      }}
      {...carouselProps}
      moreActions={<ContentDashboardBlockAdminDropdown dashboardBlockKey={block} />}
      data={contentUsages}
      item={(contentUsage, i) => (
        <ContentUsageGridItem
          key={contentUsage.id}
          isDashboardBlock
          testid={`ContentDashboardBlock.item-${i}`}
          contentUsageKey={contentUsage}
          showOverflow={false}
        />
      )}
      itemSkeleton={<ContentUsageGridItemSkeleton />}
      totalCount={contentUsages.length}
    />
  )
}

const useStyles = makeUseStyles({
  slide: {
    width: "254px",
    height: "260px",
  },
})

export default ContentDashboardBlockPreviewContent
