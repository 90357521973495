import PostCard, { PostCardSkeleton } from "@/content/post/card/PostCard"
import PinnedPostsEmptyStateIcon from "@/core/ui/images/empty-state-illustrations/pinned-posts-emptystate.svg"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { FeedDashboardBlockCardViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockCardViewFragment.graphql"
import { FeedDashboardBlockCardViewQuery } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockCardViewQuery.graphql"
import { useDashboardEvent } from "@/dashboard/context/DashboardEventProvider"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import CreatePostButton from "@/post/add/CreatePostButton"
import CreatePostInput from "@/post/add/CreatePostInput"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"

import {
  DiscoCarousel,
  DiscoCarouselSkeleton,
  DiscoEmptyState,
  DiscoIcon,
  DiscoIconButton,
} from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"
import PinnedPostsCard from "./feed/PinnedPostsCard"

interface Props extends TestIDProps {
  dashboardBlockKey: FeedDashboardBlockCardViewFragment$key
  index?: number
}

function FeedDashboardBlockCardView(props: Props) {
  const { dashboardBlockKey, index, testid = "FeedDashboardBlockCardView" } = props
  const classes = useStyles()
  const dashboardEvent = useDashboardEvent()
  const { isOneColumn } = useDashboardContext()!

  const block = useFragment<FeedDashboardBlockCardViewFragment$key>(
    graphql`
      fragment FeedDashboardBlockCardViewFragment on FeedDashboardBlock {
        title
        filter
        id
        position
        dashboardId
        dashboard {
          ...PostCardDashboardFragment
          ...usePermissionsFragment
          app {
            id
            feed {
              id
              ...usePermissionsFragment
            }
          }
        }
        organization {
          id
          viewerCanPostInFeeds
        }
        product {
          id
          viewerCanPostInFeeds
        }
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const feedPermissions = usePermissions(block.dashboard.app?.feed)
  const dashboardPermissions = usePermissions(block.dashboard)
  const { data, pagination } =
    Relay.useRefetchablePaginationQuery<FeedDashboardBlockCardViewQuery>(
      graphql`
        query FeedDashboardBlockCardViewQuery(
          $blockId: ID!
          $dashboardId: ID!
          $first: Int
          $after: String
        ) {
          feedBlock(blockId: $blockId) {
            posts(first: $first, after: $after)
              @connection(key: "FeedDashboardBlockCardView__posts") {
              __id
              totalCount
              edges {
                node {
                  id
                  ...PostCardFragment @arguments(dashboardId: $dashboardId)
                  ...PinnedPostCardFragment
                }
              }
            }
          }
        }
      `,
      {
        blockId: block.id,
        dashboardId: block.dashboardId,
        first: 3,
      },
      {
        connectionName: "feedBlock.posts",
        fetchPolicy: "network-only",
      }
    )

  const canCreatePosts = block?.dashboard.app?.feed
    ? feedPermissions.has("posts.create")
    : block?.product
    ? block?.product?.viewerCanPostInFeeds
    : block?.organization.viewerCanPostInFeeds
  const canPinPosts = block?.dashboard.app?.feed
    ? feedPermissions.has("posts.pin")
    : dashboardPermissions.has("posts.pin")
  const isPinnedPosts = block.filter === "pinned"
  const showCreatePostButton = canCreatePosts && !isPinnedPosts
  const showCreatePostInput = canCreatePosts && isPinnedPosts && isOneColumn
  const carouselProps = useDashboardBlockCarouselSize(3, block, "center")

  useEffect(() => {
    if (!dashboardEvent) return
    // Add the event listeners to refetch the feed card view when unpinning/pinning a post
    const unsubscribeUnpinnedPost = dashboardEvent.register(
      "unpinned_post",
      refetchFeedCardView
    )
    const unsubscribePinnedPost = dashboardEvent.register(
      "pinned_post",
      refetchFeedCardView
    )
    return () => {
      unsubscribeUnpinnedPost()
      unsubscribePinnedPost()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEvent])

  // One of the pagination fragments need to be set for the carousel
  if (!data) return null

  const posts = Relay.connectionToArray(data?.feedBlock?.posts)
  const totalCount = data?.feedBlock?.posts?.totalCount || 0
  const showCarousel = Boolean(
    posts.length || showCreatePostButton || (isPinnedPosts && !isOneColumn)
  )

  if (!showCreatePostInput && !showCarousel) return null
  const connectionId = data?.feedBlock?.posts?.__id

  if (isPinnedPosts) {
    return (
      <PinnedPostsCard
        block={block}
        index={index}
        posts={posts}
        pagination={{
          hasNext: pagination.hasNext,
          isLoading: pagination.isLoading,
          loadMore: () => pagination.loadMore({ first: 3 }),
        }}
      />
    )
  }

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      {showCreatePostInput && (
        <CreatePostInput
          feedId={block.dashboard.app?.feed?.id}
          refetch={refetchFeedCardView}
          noMargin={!showCarousel}
        />
      )}
      {showCarousel && (
        <DiscoCarousel
          testid={"FeedDashboardBlockCardView"}
          classes={{
            slide: classes.slide,
          }}
          {...carouselProps}
          title={block.title}
          moreActions={
            <>
              {/** Can't create post in pinned posts */}
              {showCreatePostButton && (
                <CreatePostButton
                  testid={"FeedDashboardBlock.createPostButton"}
                  feedId={block.dashboard.app?.feed?.id}
                  refetch={refetchFeedCardView}
                >
                  {(buttonProps) => {
                    return (
                      <DiscoIconButton
                        {...buttonProps}
                        tooltip={"Add Post"}
                        size={"small"}
                      >
                        <DiscoIcon icon={"add-circle"} />
                      </DiscoIconButton>
                    )
                  }}
                </CreatePostButton>
              )}
              <DashboardBlockAdminDropdown dashboardBlockKey={block} />
            </>
          }
          data={posts}
          item={(post, i) => (
            <PostCard
              key={post.id}
              testid={`${testid}-${i}`}
              className={classNames({
                // Remove the side padding for single post carousel
                [classes.singlePost]: isPinnedPosts,
              })}
              compact
              postKey={post}
              connectionId={connectionId}
              dashboardKey={block.dashboard}
              onPin={refetchFeedCardView}
              onUnpin={handleUnpinPost}
              variant={"card"}
              groovyDepths={"xs"}
              hideAttachments
              hideDescription
              hideMedia
              hideOverflow
              readOnlyReactionButton
            />
          )}
          totalCount={totalCount}
          refetch={{
            hasNext: pagination.hasNext,
            hasPrevious: pagination.hasPrevious,
            loadMore: () => pagination.loadMore({}),
            isLoading: pagination.isLoading,
          }}
          emptyState={
            showCreatePostButton ? (
              <CreatePostButton
                feedId={block.dashboard.app?.feed?.id}
                refetch={refetchFeedCardView}
              >
                {() => {
                  return (
                    <DiscoEmptyState
                      className={classes.emptyState}
                      testid={testid}
                      title={"No posts yet"}
                      subtitle={
                        isPinnedPosts
                          ? "Pin a post for it to appear here"
                          : "Create your first post now!"
                      }
                      variant={"compact"}
                      buttons={
                        <CreatePostButton
                          testid={"FeedDashboardBlockCardView.empty-state.button"}
                        />
                      }
                    />
                  )
                }}
              </CreatePostButton>
            ) : (
              /** Pinned posts */
              <DiscoEmptyState
                icon={<PinnedPostsEmptyStateIcon />}
                className={classes.emptyState}
                testid={testid}
                title={"No pinned posts"}
                subtitle={canPinPosts && "Pin posts on this forum to be featured here."}
                variant={"compact"}
              />
            )
          }
        />
      )}
    </DashboardBlockItemTemplate>
  )

  function refetchFeedCardView() {
    pagination.refetch({})
    if (!dashboardEvent) return
    dashboardEvent.dispatchEvent("refetch_feed_card_view")
  }

  function handleUnpinPost() {
    if (block.filter === "pinned" && dashboardEvent) {
      /** When we unpin a post in pinned posts, we need to refetch the card view and notify the list view to refetch */
      dashboardEvent.dispatchEvent("unpinned_post")
      return
    }

    // Otherwise, we just need to refetch the card view
    refetchFeedCardView()
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    border: "1px solid",
    borderColor: theme.palette.groovy.neutral[200],
    boxShadow: theme.palette.groovyDepths.xs,
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
  singlePost: {
    padding: theme.spacing(1.5),
    margin: `${theme.spacing(0.5)}px !important`,
  },
  slide: {
    width: "254px",
    height: "180px",
  },
}))

export function FeedDashboardBlockCardViewSkeleton() {
  return <DiscoCarouselSkeleton showTitle slidesPerView={1} item={<PostCardSkeleton />} />
}

export default Relay.withSkeleton({
  component: FeedDashboardBlockCardView,
  skeleton: FeedDashboardBlockCardViewSkeleton,
})
