/**
 * @generated SignedSource<<230de74e3bbf5429d9762627ac74ecf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type ProductAdminDashboardStatisticsQuery$variables = {
  id: string;
  roles?: ReadonlyArray<ProductRole> | null;
  shouldQueryAvgEngagementScore: boolean;
};
export type ProductAdminDashboardStatisticsQuery$data = {
  readonly block: {
    readonly product?: {
      readonly averageEngagementScore?: number;
      readonly curriculumReport: {
        readonly averageCurriculumProgress: number;
      };
      readonly hasCurriculumApp: boolean;
      readonly memberActivityReport: {
        readonly lastWeek: number;
        readonly thisWeek: number;
      };
      readonly pathwayProgressReport: {
        readonly completed: number;
      } | null;
      readonly productMemberships: {
        readonly totalCount: number;
      };
      readonly type: ProductType;
    } | null;
    readonly showAvgCompletion?: boolean;
    readonly showAvgCurriculumProgress?: boolean;
    readonly showAvgEngagementScore?: boolean;
    readonly showDailyActiveUsers?: boolean;
    readonly showRegisteredUsers?: boolean;
  } | null;
};
export type ProductAdminDashboardStatisticsQuery = {
  response: ProductAdminDashboardStatisticsQuery$data;
  variables: ProductAdminDashboardStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roles"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryAvgEngagementScore"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showRegisteredUsers",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showDailyActiveUsers",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showAvgEngagementScore",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showAvgCurriculumProgress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showAvgCompletion",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "roles",
      "variableName": "roles"
    }
  ],
  "concreteType": "ProductMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "productMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMemberReportResponse",
  "kind": "LinkedField",
  "name": "memberActivityReport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thisWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastWeek",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "condition": "shouldQueryAvgEngagementScore",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageEngagementScore",
      "storageKey": null
    }
  ]
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMemberProgressReportResponse",
  "kind": "LinkedField",
  "name": "curriculumReport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageCurriculumProgress",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": "hasCurriculumApp",
  "args": [
    {
      "kind": "Literal",
      "name": "isActive",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "kind",
      "value": "curriculum"
    }
  ],
  "kind": "ScalarField",
  "name": "hasProductApp",
  "storageKey": "hasProductApp(isActive:true,kind:\"curriculum\")"
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PathwayProgressReportResponse",
  "kind": "LinkedField",
  "name": "pathwayProgressReport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductAdminDashboardStatisticsQuery",
    "selections": [
      {
        "alias": "block",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ProductAdminDashboardBlock",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductAdminDashboardStatisticsQuery",
    "selections": [
      {
        "alias": "block",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ProductAdminDashboardBlock",
            "abstractKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "835b3ec5e51cb79acab3e9c48dc7c59c",
    "id": null,
    "metadata": {},
    "name": "ProductAdminDashboardStatisticsQuery",
    "operationKind": "query",
    "text": "query ProductAdminDashboardStatisticsQuery(\n  $id: ID!\n  $shouldQueryAvgEngagementScore: Boolean!\n  $roles: [ProductRole!]\n) {\n  block: node(id: $id) {\n    __typename\n    ... on ProductAdminDashboardBlock {\n      showRegisteredUsers\n      showDailyActiveUsers\n      showAvgEngagementScore\n      showAvgCurriculumProgress\n      showAvgCompletion\n      product {\n        type\n        productMemberships(roles: $roles) {\n          totalCount\n        }\n        memberActivityReport {\n          thisWeek\n          lastWeek\n        }\n        averageEngagementScore @include(if: $shouldQueryAvgEngagementScore)\n        curriculumReport {\n          averageCurriculumProgress\n        }\n        hasCurriculumApp: hasProductApp(kind: curriculum, isActive: true)\n        pathwayProgressReport {\n          completed\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e60af4f3359a6543d2320ef8d2139545";

export default node;
