import ContentDashboardBlockFullContent from "@/dashboard/blocks/kinds/ContentDashboardBlockFullContent"
import ContentDashboardBlockPreviewContent from "@/dashboard/blocks/kinds/ContentDashboardBlockPreviewContent"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { ContentDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/ContentDashboardBlockFragment.graphql"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: ContentDashboardBlockFragment$key
  index?: number
}

function ContentDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props

  const block = useFragment<ContentDashboardBlockFragment$key>(
    graphql`
      fragment ContentDashboardBlockFragment on ContentDashboardBlock {
        mode
        contentUsages {
          totalCount
        }
        ...DashboardBlockItemTemplateFragment
        ...ContentDashboardBlockPreviewContentFragment
        ...ContentDashboardBlockFullContentFragment
      }
    `,
    dashboardBlockKey
  )

  const isSingleContent = (block.contentUsages?.totalCount || 0) === 1

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      {block.mode === "preview" && !isSingleContent ? (
        <ContentDashboardBlockPreviewContent dashboardBlockKey={block} />
      ) : (
        <ContentDashboardBlockFullContent dashboardBlockKey={block} />
      )}
    </DashboardBlockItemTemplate>
  )
}

export default ContentDashboardBlock
