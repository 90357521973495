import GenerateWithAIButton from "@/content/ai/button/GenerateWithAIButton"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CurriculumHeaderCreateButton from "@/product/course/curriculum/common/header/components/create-button/CurriculumHeaderCreateButton"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIButton from "@components/ai/AIButton"
import { DiscoEmptyState } from "@disco-ui"
import { useEffect } from "react"
import { generatePath, useHistory } from "react-router-dom"

type Props = {
  curriculumId: GlobalID
  refetchCurriculum?: () => void
}

export default function CurriculumDashboardBlockEmptyState({
  curriculumId,
  refetchCurriculum,
}: Props) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const history = useHistory()

  const {
    status: aiGenerationStatus,
    refetchStatus,
    hasIncompleteGeneration,
    isGeneratingEntity,
  } = useTrackAIGenerationStatus({
    entityId: activeProduct?.curriculum?.id || "",
  })

  useEffect(() => {
    // Refetch the curriculum when the AI generation is completed in the background
    if (aiGenerationStatus === "completed") {
      refetchCurriculum?.()
    }
  }, [refetchCurriculum, aiGenerationStatus])

  return (
    <DiscoEmptyState
      className={classes.emptyState}
      testid={"CurriculumDashboardBlock"}
      title={
        hasIncompleteGeneration
          ? "Curriculum Preview Generation In Progress"
          : "No modules yet"
      }
      subtitle={
        "Build your Curriculum with modules that contain videos, lessons, assignments, and quizzes."
      }
      variant={"compact"}
      buttons={
        <>
          <GenerateWithAIButton
            curriculumId={curriculumId}
            onSuccess={handleSuccessfulGeneration}
          >
            {(btnProps) => (
              <AIButton
                {...btnProps}
                onClick={(e) => {
                  e.stopPropagation()
                  btnProps.onClick(e)
                }}
              >
                {isGeneratingEntity
                  ? "Generating Curriculum..."
                  : hasIncompleteGeneration
                  ? "Edit Curriculum Preview"
                  : "Generate Curriculum with AI"}
              </AIButton>
            )}
          </GenerateWithAIButton>
          {!hasIncompleteGeneration && <CurriculumHeaderCreateButton />}
        </>
      }
    />
  )

  function handleSuccessfulGeneration() {
    if (!activeProduct) return

    // Refetch the AI generation status
    refetchStatus()

    // Redirect to the curriculum overview page
    history.push(
      generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
        productSlug: activeProduct.slug,
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    border: "1px solid",
    borderColor: theme.palette.groovy.neutral[200],
    boxShadow: theme.palette.groovyDepths.xs,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))
