import ROUTE_NAMES from "@/core/route/util/routeNames"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import EventsDashboardBlockListItem, {
  EventsDashboardBlockListItemSkeleton,
} from "@/dashboard/blocks/kinds/EventsDashboardBlockListItem"
import { EventsDashboardBlockListViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockListViewFragment.graphql"
import { EventsDashboardBlockListViewQuery } from "@/dashboard/blocks/kinds/__generated__/EventsDashboardBlockListViewQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import classNames from "classnames"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

interface Props {
  dashboardBlockKey: EventsDashboardBlockListViewFragment$key
  index?: number
}

function EventsDashboardBlockListView({ dashboardBlockKey, index }: Props) {
  const history = useHistory()
  const classes = useStyles()
  const onShowHoverClasses = useShowOnHoverStyles()

  const block = useFragment<EventsDashboardBlockListViewFragment$key>(
    graphql`
      fragment EventsDashboardBlockListViewFragment on UpcomingEventsDashboardBlock {
        productId
        organizationId
        count
        position
        view
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const { organization, product } = useLazyLoadQuery<EventsDashboardBlockListViewQuery>(
    graphql`
      query EventsDashboardBlockListViewQuery($id: ID!, $first: Int, $productId: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            occurrences(first: $first, datetimeFilter: upcoming) {
              edges {
                node {
                  id
                  product {
                    type
                  }
                  ...EventsDashboardBlockListItem
                }
              }
            }
            ...usePermissionsFragment
          }
        }
        product: node(id: $productId) {
          ... on Product {
            slug
            eventOccurrences(first: $first, isUpcoming: true) {
              edges {
                node {
                  id
                  ...EventsDashboardBlockListItem
                }
              }
            }
            ...usePermissionsFragment
          }
        }
      }
    `,
    {
      id: block.productId ? "" : block.organizationId,
      first: block.count,
      productId: block.productId || "",
    },
    { fetchPolicy: "network-only" }
  )

  const occurrences = organization?.occurrences
    ? Relay.connectionToArray(organization.occurrences)
    : Relay.connectionToArray(product?.eventOccurrences)

  const permissions = usePermissions(product || organization)
  if (!permissions.has("events.create") && !occurrences.length) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection
        padding={2}
        className={classNames(classes.container, onShowHoverClasses.hoverable)}
      >
        <div className={classes.header}>
          <DiscoText variant={"body-lg-600"}>{"Events"}</DiscoText>
          <DashboardBlockAdminDropdown
            dashboardBlockKey={block}
            className={onShowHoverClasses.showable}
          />
        </div>
        <DiscoSection className={classes.events}>
          {occurrences.length > 0 ? (
            occurrences.map((occurrence) => (
              <EventsDashboardBlockListItem
                key={occurrence.id}
                occurrenceKey={occurrence}
                hideDate={block.position === "side"}
              />
            ))
          ) : (
            <DiscoText
              className={classes.empty}
              variant={"body-sm"}
              color={"text.secondary"}
            >
              {"No events"}
            </DiscoText>
          )}
        </DiscoSection>
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          width={"100%"}
          onClick={redirectToEventsPage}
        >
          {"View All"}
        </DiscoButton>
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )

  function redirectToEventsPage() {
    const path = product?.slug
      ? generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
          productSlug: product.slug!,
        })
      : ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT

    history.push(path)
  }
}

export function EventsDashboardBlockListViewSkeleton() {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <DiscoSection>
        <DiscoTextSkeleton height={"10%"} variant={"body-lg-600"} width={"60%"} />
      </DiscoSection>
      <div
        style={{
          paddingLeft: theme.spacing(3),
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
        }}
      >
        <EventsDashboardBlockListItemSkeleton />
        <EventsDashboardBlockListItemSkeleton />
      </div>
      <DiscoSection>
        <DiscoButtonSkeleton width={"100%"} />
      </DiscoSection>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,

    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  events: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: EventsDashboardBlockListView,
  skeleton: EventsDashboardBlockListViewSkeleton,
})
