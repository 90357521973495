import ROUTE_NAMES from "@/core/route/util/routeNames"
import lightForYouPage from "@/core/ui/images/light-for-you-dashboard.png"
import lightPersonalizedBlocks from "@/core/ui/images/light-personalized-blocks.png"
import lightSimplifiedView from "@/core/ui/images/light-simplified-view.png"
import darkForYouPage from "@/core/ui/images/dark-for-you-dashboard.png"
import darkPersonalizedBlocks from "@/core/ui/images/dark-personalized-blocks.png"
import darkSimplifiedView from "@/core/ui/images/dark-simplified-view.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Swiper from "@components/carousel/Swiper"
import SwiperSlide from "@components/carousel/SwiperSlide"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useMemo, useState } from "react"
import { graphql, useMutation } from "react-relay"
import { useHistory } from "react-router-dom"
import { Swiper as SwiperType } from "swiper"
import { DashboardMigrationModalMutation } from "./__generated__/DashboardMigrationModalMutation.graphql"
import { useTheme } from "@material-ui/core"

const slideInformation = [
  {
    heading: 'Your "Home" page is becoming "For You"',
    description: "Create a refined and personalized experience for every member.",
    lightImg: lightForYouPage,
    darkImg: darkForYouPage,
  },
  {
    heading: "New simplified, single column layout",
    description:
      "A unified layout simplifies the design and is easily customized with our drag-and-drop editor.",
    lightImg: lightSimplifiedView,
    darkImg: darkSimplifiedView,
  },
  {
    heading: "Personalized blocks for every Member",
    description:
      "Members can see important reminders such as upcoming quizzes and overdue assignments and can easily jump back into recently viewed content.",
    lightImg: lightPersonalizedBlocks,
    darkImg: darkPersonalizedBlocks,
  },
]

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function DashboardMigrationModal({ isOpen, onClose }: Props) {
  const history = useHistory()
  const classes = useStyles()
  const [carouselState, setCarouselState] = useState<SwiperType | null>(null)
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null)
  const theme = useTheme()

  const [migrateDashboard, isMigrating] = useMutation<DashboardMigrationModalMutation>(
    graphql`
      mutation DashboardMigrationModalMutation {
        migrateDashboard {
          node {
            organization {
              forYouDashboard {
                published
              }
              dashboard {
                published
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const config = useMemo(() => {
    return {
      pagination: true,
      setParentCarouselState: setCarouselState,
      slidesPerView: 1,
      totalCount: slideInformation.length,
      currentSliceSize: slideInformation.length,
      navigation: {
        nextEl,
        prevEl: null,
      },
      partialNavigation: true,
    }
  }, [nextEl])

  return (
    <DiscoModal
      testid={"DashboardMigrationModal"}
      classes={{
        container: classes.modal,
        body: classes.modalBody,
        buttons: classes.buttons,
        footer: classes.modalFooter,
      }}
      modalContentLabel={"Migrate to new dashboard"}
      isOpen={isOpen}
      onClose={onClose}
      title={""}
      hideCloseIcon
      hideHeader
      shouldCloseOnOverlayClick
      buttons={
        <>
          <DiscoButton
            ref={(el) => setNextEl(el)}
            testid={"DashboardMigrationModal.next"}
            className={classes.button}
            size={"large"}
            style={carouselState?.isEnd ? { display: "none" } : {}}
          >
            {"Next"}
          </DiscoButton>
          {carouselState?.isEnd && (
            <>
              <DiscoButton
                onClick={onClose}
                disabled={isMigrating}
                className={classes.button}
                size={"large"}
                variant={"outlined"}
                color={"grey"}
              >
                {"Do it later"}
              </DiscoButton>
              <DiscoButton
                testid={"DashboardMigrationModal.start-customizing"}
                onClick={clickMigrate}
                disabled={isMigrating}
                className={classes.button}
                size={"large"}
              >
                {"Start Customizing"}
              </DiscoButton>
            </>
          )}
        </>
      }
      body={
        <div className={classes.body}>
          <div>
            <Swiper swiperPaginationTop={403} config={config}>
              {slideInformation.slice(0, 4).map((item, index) => (
                <SwiperSlide key={item.heading} index={index}>
                  <div className={classes.slide}>
                    <DiscoImage
                      src={theme.palette.type === "dark" ? item.darkImg : item.lightImg}
                      className={classes.migrateHomeImg}
                    />
                    <div className={classes.slideText}>
                      <DiscoText variant={"heading-sm-600"}>{item.heading}</DiscoText>
                      <DiscoText align={"center"} color={"text.secondary"}>
                        {item.description}
                      </DiscoText>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      }
    />
  )

  function clickMigrate() {
    migrateDashboard({
      variables: {},
      onError(error) {
        displayErrorToast(error)
      },
      onCompleted() {
        displaySuccessToast({
          message: "For You dashboard created!",
        })
      },
    })
    history.push(ROUTE_NAMES.COMMUNITY.HOME.FOR_YOU)
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  modal: {
    width: "720px",
  },
  slide: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(8),
  },
  modalBody: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  migrateHomeImg: {
    borderRadius: theme.measure.borderRadius.big,
  },
  button: {
    // Overriding MUI styles which disables a button if no onClick is provided
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  slideText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    alignSelf: "center",
    gap: theme.spacing(1.5),
    margin: "auto",
  },
  modalFooter: {
    padding: theme.spacing(0, 3, 3, 3),
    alignItems: "center",
  },
}))
