import ContentUsageGridItem, {
  ContentUsageGridItemSkeleton,
} from "@/content-usage/modules/grid/ContentUsageGridItem"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import { CollectionFolderDashboardBlockContentFragment$key } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentFragment.graphql"
import { CollectionFolderDashboardBlockContentPaginationQuery } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentPaginationQuery.graphql"
import { CollectionFolderDashboardBlockContentQuery } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContentQuery.graphql"
import { CollectionFolderDashboardBlockContent_PaginationFragment$key } from "@/dashboard/blocks/kinds/__generated__/CollectionFolderDashboardBlockContent_PaginationFragment.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoCarouselSkeleton, DiscoEmptyState } from "@disco-ui"
import {
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { graphql } from "relay-runtime"

interface CollectionFolderDashboardBlockContentProps {
  dashboardBlockKey: CollectionFolderDashboardBlockContentFragment$key
  className?: string
}

function CollectionFolderDashboardBlockContent({
  dashboardBlockKey,
  className,
}: CollectionFolderDashboardBlockContentProps) {
  const classes = useStyles()

  const block = useFragment<CollectionFolderDashboardBlockContentFragment$key>(
    graphql`
      fragment CollectionFolderDashboardBlockContentFragment on CollectionFolderDashboardBlock {
        position
        contentId
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  const { content } = useLazyLoadQuery<CollectionFolderDashboardBlockContentQuery>(
    graphql`
      query CollectionFolderDashboardBlockContentQuery($id: ID!, $first: Int) {
        content: node(id: $id) {
          ... on Content {
            name
            usages {
              edges {
                node {
                  id
                  collection {
                    app {
                      id
                    }
                  }
                }
              }
            }
            ...CollectionFolderDashboardBlockContent_PaginationFragment
              @arguments(first: $first)
          }
        }
      }
    `,
    { id: block.contentId, first: 3 }
  )

  const {
    data,
    hasPrevious,
    loadNext,
    hasNext,
    isLoadingNext: contentIsLoadingNext,
    refetch,
  } = usePaginationFragment<
    CollectionFolderDashboardBlockContentPaginationQuery,
    CollectionFolderDashboardBlockContent_PaginationFragment$key
  >(
    graphql`
      fragment CollectionFolderDashboardBlockContent_PaginationFragment on Content
      @refetchable(queryName: "CollectionFolderDashboardBlockContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        children(first: $first, after: $after)
          @connection(key: "CollectionFolderDashboardBlockContent_children") {
          __id
          totalCount
          edges {
            node {
              id
              content {
                name
              }
              ...ContentUsageGridItemFragment
            }
          }
        }
      }
    `,
    content
  )

  const carouselProps = useDashboardBlockCarouselSize(4, block, "center")

  // Listen for invalidation when adding content item
  useSubscribeToInvalidationState(data?.children ? [data?.children?.__id] : [], () => {
    refetch({
      first: 3,
    })
  })

  const contentUsages = Relay.connectionToArray(data?.children)
  const totalCount = data?.children?.totalCount || 0

  const usage = Relay.connectionToArray(content?.usages)[0]
  if (!usage?.collection?.app) return null

  return (
    <DiscoCarousel
      classes={{
        slide: classes.slide,
      }}
      {...carouselProps}
      title={content?.name}
      moreActions={
        <DashboardBlockAdminDropdown dashboardBlockKey={block} className={className} />
      }
      data={contentUsages}
      item={(contentUsage, i) => (
        <ContentUsageGridItem
          key={contentUsage.id}
          isDashboardBlock
          testid={`CollectionFolderDashboardBlock.item-${i}`}
          contentUsageKey={contentUsage}
          showOverflow={false}
        />
      )}
      testid={"CollectionFolderDashboardBlock"}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => {
          loadNext(3)
        },
        isLoading: contentIsLoadingNext,
      }}
      itemSkeleton={<ContentUsageGridItemSkeleton />}
      totalCount={totalCount}
      emptyState={
        <DiscoEmptyState
          className={classes.emptyState}
          testid={"CollectionFolderDashboardBlockContent"}
          title={"No Items"}
          variant={"compact"}
        />
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  slide: {
    width: "254px",
    height: "260px",
  },
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))

export function CollectionFolderDashboardBlockContentSkeleton() {
  return (
    <DiscoCarouselSkeleton
      showTitle
      item={<ContentUsageGridItemSkeleton />}
      slidesPerView={3}
    />
  )
}

export default Relay.withSkeleton({
  component: CollectionFolderDashboardBlockContent,
  skeleton: CollectionFolderDashboardBlockContentSkeleton,
})
